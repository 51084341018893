.job-content {
    padding-top: 64px;
    position: relative;
    z-index: 5;
    margin-bottom: -20px;

    &__wrap {
        max-width: vw(1058);
        padding-left: ac(88px, 0px);
        position: relative;
        z-index: 10;

        &::before {
            content: '';
            position: absolute;
            top: -130px;
            left: calc(100% + 32px);
            width: ac(264px, 162px);
            height: ac(900px, 510px);
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='608' fill='none'%3E%3Cpath stroke='%2334EDFF' d='M45.301 607.5H.5v-54.239h13.173c14.92 0 25.161-3.442 30.618-10.432l.001-.002c2.704-3.503 4.704-8.564 6.031-15.14 1.328-6.583 1.99-14.719 1.99-24.405 0-19.342-.48-37.944-1.44-55.872l-.5.026.5-.027c-.96-17.874-1.44-37.67-1.44-59.355 0-26.848 4.318-46.151 12.86-58.012 8.548-11.902 21.827-19.967 39.971-24.191l.387-.09v-3.623l-.387-.09c-18.144-4.224-31.423-12.289-39.971-24.191-8.542-11.894-12.86-31.198-12.86-58.012 0-21.651.48-41.447 1.44-59.356.96-17.928 1.44-36.53 1.44-55.872 0-9.686-.662-17.823-1.99-24.405-1.327-6.576-3.327-11.636-6.03-15.14-5.424-7.027-15.703-10.434-30.62-10.434H.5V.5h44.801c31.567 0 54.682 7.162 69.421 21.416 14.719 14.235 22.156 40.327 22.156 78.457 0 12.724-.343 24.029-1.061 33.882a912.555 912.555 0 0 1-2.535 28.615 1075.397 1075.397 0 0 0-2.537 27.598c-.72 9.211-1.098 19.463-1.098 30.791 0 6.191 1.108 12.378 3.284 18.557 2.184 6.202 5.685 11.787 10.53 16.787 4.858 5.014 11.263 9.058 19.231 12.143 7.871 3.048 17.466 4.832 28.808 5.335v59.838c-11.342.503-20.937 2.287-28.808 5.335-7.968 3.085-14.373 7.129-19.231 12.143-4.845 5-8.346 10.619-10.53 16.786v.001c-2.176 6.179-3.284 12.366-3.284 18.557 0 11.328.378 21.58 1.098 30.791.72 9.198 1.543 18.398 2.537 27.598a912.555 912.555 0 0 1 2.535 28.615c.718 9.887 1.061 21.192 1.061 33.882 0 38.13-7.437 64.222-22.156 78.457-14.74 14.254-37.82 21.416-69.42 21.416Z'/%3E%3C/svg%3E") center / contain no-repeat;
        }
    }

    .btn {
        margin: 43px 0 41px;
    }

    .share-block {
        span {
            color: var(--tealish);
        }

        .socials__item {
            background: var(--cyan);

            &:hover {
                background: var(--caribbean);
                color: var(--white);
            }
        }
    }

    .decor-item {
        position: absolute;
        background-position: center bottom;
        background-size: contain;
        background-repeat: no-repeat;

        &:nth-of-type(1) {
            right: ac(163px, 42px);
            bottom: ac(-75px, -20px);
            width: vw(149);
            height: vw(149);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='176' height='176' fill='none'%3E%3Cpath fill='%23020519' d='M176 64.5v47h-64.5V176h-47v-64.5H0v-47h64.5V0h47v64.5H176Z'/%3E%3C/svg%3E");
            z-index: 5;
        }

        &:nth-of-type(2) {
            right: ac(82px, 18px);
            bottom: 10px;
            width: vw(156);
            height: vw(296);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='184' height='350' fill='none'%3E%3Cpath fill='%2334EDFF' d='M184 349.972H0v-151.23C0 147.77 10.856 107.571 32.567 78.09 54.28 48.61 91.51 22.59 144.234 0L184 73.561c-32.423 14.881-54.854 29.678-67.265 44.418-12.411 14.741-19.322 32.154-20.733 52.267h87.969V350l.029-.028Z'/%3E%3C/svg%3E");
        }
    }

    @mixin tab-sm {
        &__wrap {
            max-width: 100%;

            &::before {
                display: none;
            }
        }
    }
}