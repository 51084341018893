.jobseekers {
    background-color: var(--cyan);

    &__wrapper, &__title-box, &__col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__wrapper {
        padding: 100px 0 ac(100px, 170px, 550, 1440);
        position: relative;

        &::before {
            content: '';
            position: absolute;
            right: ac(29px, -67px);
            bottom: -33px;
            width: ac(192px, 162px);
            height: ac(608px, 510px);
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='608' fill='none'%3E%3Cpath stroke='%2334EDFF' d='M45.301 607.5H.5v-54.239h13.173c14.92 0 25.161-3.442 30.618-10.432l.001-.002c2.704-3.503 4.704-8.564 6.031-15.14 1.328-6.583 1.99-14.719 1.99-24.405 0-19.342-.48-37.944-1.44-55.872l-.5.026.5-.027c-.96-17.874-1.44-37.67-1.44-59.355 0-26.848 4.318-46.151 12.86-58.012 8.548-11.902 21.827-19.967 39.971-24.191l.387-.09v-3.623l-.387-.09c-18.144-4.224-31.423-12.289-39.971-24.191-8.542-11.894-12.86-31.198-12.86-58.012 0-21.651.48-41.447 1.44-59.356.96-17.928 1.44-36.53 1.44-55.872 0-9.686-.662-17.823-1.99-24.405-1.327-6.576-3.327-11.636-6.03-15.14-5.424-7.027-15.703-10.434-30.62-10.434H.5V.5h44.801c31.567 0 54.682 7.162 69.421 21.416 14.719 14.235 22.156 40.327 22.156 78.457 0 12.724-.343 24.029-1.061 33.882a912.555 912.555 0 0 1-2.535 28.615 1075.397 1075.397 0 0 0-2.537 27.598c-.72 9.211-1.098 19.463-1.098 30.791 0 6.191 1.108 12.378 3.284 18.557 2.184 6.202 5.685 11.787 10.53 16.787 4.858 5.014 11.263 9.058 19.231 12.143 7.871 3.048 17.466 4.832 28.808 5.335v59.838c-11.342.503-20.937 2.287-28.808 5.335-7.968 3.085-14.373 7.129-19.231 12.143-4.845 5-8.346 10.619-10.53 16.786v.001c-2.176 6.179-3.284 12.366-3.284 18.557 0 11.328.378 21.58 1.098 30.791.72 9.198 1.543 18.398 2.537 27.598a912.555 912.555 0 0 1 2.535 28.615c.718 9.887 1.061 21.192 1.061 33.882 0 38.13-7.437 64.222-22.156 78.457-14.74 14.254-37.82 21.416-69.42 21.416Z'/%3E%3C/svg%3E") center / contain no-repeat;
        }
    }

    &__img {
        position: absolute;
        top: ac(-151px, -50px, 768, 1440);
        left: ac(0px, 8px, 768, 1440);
        width: ac(412px, 314px);
        height: ac(408px, 310px);

        img {
            filter: grayscale(1);
            transform: scale(-1, 1);
        }

        &::before, &::after {
            content: '';
            position: absolute;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            z-index: 1;
        }

        &::before {
            bottom: ac(10px, 2px);
            left: ac(-38px, -10px);
            width: ac(64px, 33px);
            height: ac(304px, 162px);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='304' fill='none'%3E%3Cpath fill='%23C7FD89' d='M11.245 206.912 2.168 62.682 0 0h64l-2.168 62.683-9.077 144.229h-41.51ZM64 304H0v-69.752h64V304Z'/%3E%3C/svg%3E");
        }

        &::after {
            left: ac(-20px, -1px);
            bottom:ac(-87px, -49px);
            width: ac(92px, 48px);
            height: ac(175px, 94px);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='92' height='175' fill='none'%3E%3Cpath fill='%2334EDFF' d='M92 174.986H0V99.371c0-25.486 5.428-45.585 16.284-60.326C27.139 24.305 45.755 11.295 72.117 0L92 36.78c-16.212 7.441-27.427 14.84-33.633 22.21-6.205 7.37-9.66 16.076-10.366 26.133h43.985V175l.014-.014Z'/%3E%3C/svg%3E");
        }
    }

    &__title-box {
        max-width: 628px;
        padding-left: ac(30px, 16px);
        margin-left: ac(540px, 344px, 768, 1440);

        &:before {
            background: var(--light-blue);
        }
    }

    &__title , &__subtitle, &__descr, &__name, &__text {
        color: var(--white);
    }

    &__title {
        margin-bottom: ac(26px, 20px);

        span {
            &::after, &::before {
                background-color: var(--light-blue);
            }
        }
    }

    &__subtitle {
        font-family: var(--font-alt);
        margin-bottom: ac(16px, 17px);
        font-size: ac(21px, 17px);
        line-height: ac(24px, 20px);
        font-weight: 700;
    }

    &__descr {
        max-width: 480px;
    }

    &__text-box {
        margin-top: ac(93px, 49px);
        display: flex;
        align-items: flex-start;
    }

    &__col {
        flex: 0 0 auto;
        max-width: ac(412px, 320px, 1024, 1440);
        margin-right: ac(128px, 60px, 1024, 1440);
    }

    &__btn {
        margin-top: ac(41px, 39px);
        border-color: var(--white);

        span {
            color: var(--white)
        }

        &:hover {
            background-color: var(--white);

            span {
                color: var(--cyan);
            }
        }
    }

    &__slider-box {
        max-width: 628px;
    }

    &__slider {
        overflow: visible;
    }

    &__name {
        font-family: var(--font-sec);
        font-size: 18px;
        margin-bottom: 13px;
    }

    &__text {
        font-size: 15px;
        line-height: ac(17px, 19px);

        max-height: ac(138px, 150px);
        padding-right: 15px;
        width: calc(100% + 15px);

		.simplebar-track {
			width: 8px;
			border-radius: 20px;
			cursor: pointer;
		}

		.simplebar-scrollbar::before {
			background-color: var(--white);
			opacity: 1;
		}
    }

    @media (min-width: 1024px) {
        .swiper-wrapper {
            padding-top: 1px;
            width: auto;
            flex-wrap: wrap;
            gap: 41px 20px;
            margin-right: -20px;
        }

        .swiper-slide {
            width: calc(100% / 2 - 20px);
        }
    }

    @mixin tab {
        &__text-box {
            width: 100%;
            flex-direction: column;
        }

        &__slider-box, &__col {
            max-width: unset;
        }

        &__col {
            margin-right: 0;

            .jobseekers__subtitle {
                font-family: var(--font-sec);
                font-weight: 600;
            }
        }

        &__slider-box {
            margin-top: 53px;
            width: 100%;
        }

        &__slider-nav {
            margin-top: 40px;
            justify-content: flex-end;

            .slider-btn {
                border-color: var(--white);

                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='24' fill='none'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='bevel' stroke-width='2' d='M11.25 22.5.75 12l10.5-10.5'/%3E%3C/svg%3E");
                }

                &:hover {
                    background-color: var(--white);
                    border-color: var(--white);

                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='24' fill='none'%3E%3Cpath stroke='%231B76B9' stroke-linecap='round' stroke-linejoin='bevel' stroke-width='2' d='M11.25 22.5.75 12l10.5-10.5'/%3E%3C/svg%3E");
                    }
                }

            }

            .slider-btn--next {
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='24' fill='none'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='bevel' stroke-width='2' d='M1.75 22.5 12.25 12 1.75 1.5'/%3E%3C/svg%3E");
                }
                
                &:hover {
                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='24' fill='none'%3E%3Cpath stroke='%231B76B9' stroke-linecap='round' stroke-linejoin='bevel' stroke-width='2' d='M1.75 22.5 12.25 12 1.75 1.5'/%3E%3C/svg%3E");
                    }
                }
            }
        }
    }

    @mixin tab-sm {
        &__wrapper {
            padding-top: 226px;
        }

        &__title-box {
            max-width: unset;
            margin-left: 0;
        }

        &__img {
            top: -162px;
        }
    }

    @mixin mob-lg {
        &__wrapper {
            padding-bottom: 281px;

            &::before {
                bottom: ac(-33px, 521px);
            }
        }

        &__slider-nav {
            justify-content: center;
        }
    }
}