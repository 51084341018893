.results {
    position: relative;
    margin-left: ac(59px, -20px, 768, 1440);
    margin-top: -84px;
    z-index: 1;

    &__wrapper {
        position: relative;
        padding: ac(80px, 40px) ac(109px, 40px);

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: calc(100% + 50vw);
            background-color: var(--cyan);
        }

        &::after {
            content: '';
            position: absolute;
            right: ac(-138px, -50px);
            bottom: 15px;
            width: ac(364px, 200px);
            height: ac(1173px, 700px);
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='364' height='1173' fill='none'%3E%3Cpath stroke='%2334EDFF' d='m280.383 126.022-.002.002c-5.066 6.68-8.839 16.364-11.347 29.016-2.51 12.658-3.761 28.321-3.761 46.991 0 37.308.909 73.187 2.729 107.767 1.818 34.495 2.728 72.696 2.728 114.538 0 51.827-8.186 89.192-24.465 112.196l-.001.001c-16.284 23.076-41.567 38.682-76.04 46.849l-.385.091V589.722l.385.091c34.473 8.167 59.756 23.773 76.04 46.849 16.28 23.07 24.466 60.435 24.466 112.197 0 41.777-.91 79.977-2.728 114.538-1.82 34.58-2.729 70.459-2.729 107.767 0 18.67 1.251 34.336 3.761 46.986 2.509 12.66 6.281 22.34 11.347 29.02 10.15 13.38 29.455 19.95 57.698 19.95H363.5v105.38h-85.384c-59.898 0-103.856-13.83-131.918-41.45-28.045-27.59-42.143-78.1-42.143-151.698 0-24.555.649-46.377 2.012-65.402a1794.748 1794.748 0 0 1 4.806-55.22c1.82-17.743 3.444-35.488 4.809-53.233 1.365-17.755 2.08-37.523 2.08-59.367 0-11.887-2.089-23.769-6.199-35.644-4.117-11.898-10.718-22.616-19.86-32.218-9.155-9.615-21.239-17.385-36.3-23.319-14.962-5.896-33.246-9.34-54.903-10.281V528.332c21.657-.941 39.941-4.385 54.904-10.281 15.06-5.934 27.144-13.704 36.3-23.319 9.142-9.602 15.742-20.385 19.859-32.217v-.001c4.11-11.875 6.199-23.757 6.199-35.644 0-21.844-.715-41.612-2.08-59.367-1.365-17.744-2.924-35.489-4.809-53.235a1794.332 1794.332 0 0 1-4.806-55.218c-1.363-19.09-2.012-40.912-2.012-65.402 0-73.595 14.098-124.103 42.143-151.703C174.26 14.328 218.153.5 278.116.5H363.5v105.571h-25.421c-28.246 0-47.482 6.636-57.696 19.951Z'/%3E%3C/svg%3E") center / contain no-repeat;
            z-index: 2;
        }
    }

    &__title {
        position: relative;
        color: var(--white);
        z-index: 2;
        
        span {
            position: relative;
            bottom: 0;
            right: 0;
            display: inline-flex;

            &::before, &::after {
                background-color: var(--light-blue);
            }
        }

        &::before {
            left: ac(-30px, -16px);
            background-color: var(--light-blue);
        }
    }

    &__subtitle {
        font-size: 21px;
        position: relative;
        margin-top: 18px;
        max-width: 640px;
        line-height: 140%;
        color: var(--white);
    }

    &__count-box {
        position: relative;
        display: flex;
        gap: 20px 30px;
        margin-top: ac(60px, 30px);
        z-index: 2;
        justify-content: space-between;
    }

    &__col {
        max-width: 30%;

        &:nth-child(2) {
            span {
                color: var(--yellow-green);
            }
        }
    }

    &__num {
        display: flex;
        font-size: ac(44px, 38px);
        line-height: ac(50px, 44px);
        font-weight: 700;
        font-family: var(--font-sec);

        span {
            color: var(--light-blue);
        }
    }

    &__count, &__name, &__descr {
        color: var(--white);
    }

    &__name {
        margin-top: ac(20px, 10px);
        font-weight: 700;
        font-size: 21px;
        line-height: 26px;
        letter-spacing: .6px;
    }

    &__descr {
        margin-top: ac(12px, 5px);
    }

    @mixin tab-sm {
        width: calc(100% + 40px);

        &__count-box {
            flex-direction: column;
        }

        &__col {
            max-width: 100%;
        }
    }
}