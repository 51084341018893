.jobs {
    &__wrapper, &__title-box, &__search button, &__slider-box {
        display: flex;
    }

    &__wrapper {
        flex-wrap: wrap;
        position: relative;
        padding: 100px 0 0;
    }

    &__img {
        position: absolute;
        top: ac(-231px, -150px, 768, 1440);
        left: 0;
        width: 100%;
        max-width: ac(412px, 335px);
        height: ac(421px, 343px);

        img {
            filter: grayscale(1);
        }

        &::after {
            content: '';
            position: absolute;
            top: ac(50px, 41px);
            right: 0;
            width: ac(196px, 159px);
            height: ac(217px, 177px);
            mix-blend-mode: overlay;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='196' height='217' fill='none'%3E%3Cpath fill='%23C7FD89' d='M196 61.722 76.891 108.311 196 154.886V217L0 134.38V82.475L196 0v61.736-.014Z' style='mix-blend-mode:overlay'/%3E%3C/svg%3E") center / contain no-repeat;
        }
    }

    &__title-box {
        position: relative;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        max-width: 800px;
        margin-left: ac(432px, 360px, 768, 1440);
        padding-left: ac(30px, 16px);

        &:before {
            background: var(--cyan);
        }
    }

    &__title {
        margin-bottom: ac(26px, 20px);

        span {
            &::after, &::before {
                background-color: var(--cyan);
            }
        }
    }

    &__subtitle {
        font-family: var(--font-alt);
        font-size: ac(21px, 17px);
        line-height: ac(24px, 20px);
        font-weight: 700;
        max-width: ac(483px, 400px, 1024, 1440);
    }

    &__btn {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &__keyword {
        width: 100%;
        margin-top: ac(18px, 43px);
        margin-bottom: ac(15px, 19px);
        font-family: var(--font-sec);
        font-size: 13px;
    }

    &__search {
        position: relative;
        width: 628px;

        input {
            width: 100%;
            padding: 0 60px 0 24px;
            border-radius: 25.5px;
            border: 1px solid var(--tealish);

            &, &::placeholder {
                line-height: 49px;
            }
        }

        button {
            position: absolute;
            align-items: center;
            justify-content: center;
            top: 5px;
            right: 5px;
            width: 41px;
            height: 41px;
            border-radius: 100%;
            background-color: var(--caribbean);
            transition: .3s ease;

            span {
                width: 20px;
                height: 22px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='23' fill='none'%3E%3Cpath stroke='%23fff' stroke-width='2' d='m15.707 16.293 6 6M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'/%3E%3C/svg%3E") center / contain no-repeat;
            }

            &:hover {
                background-color: var(--cyan);
            }
        }
    }

    &__slider-nav {
        margin-left: auto;
    }

    &__slider-box {
        margin-top: ac(53px, 30px);
        width: 100%;
    }

    &__slide {
        padding: ac(40px, 30px);
        background-color: var(--cyan);
        border-radius: 25.5px;
        transition: all .7s ease, background-color .3s ease !important;

        &>* {
            color: var(--white);
            transition: .3s ease;
        }

        &:hover {
            box-shadow: inset 0 0 0 2px var(--tealish);
            background-color: var(--white);

            &>* {
                color: var(--tealish);
            }

            li {
                &::before, &::after {
                    background-color: var(--tealish);
                }
            }
        }
    }

    &__name {
        font-size: ac(24px, 20px);
        line-height: ac(29px, 24px);
        font-family: var(--font-sec);
    }

    &__list {
        margin-top: ac(22px, 20px);

        li {
            padding-left: 16px;
            font-size: ac(18px, 15px);
            font-family: var(--font-sec);

            &:not(:last-child) {
                margin-bottom: ac(12px, 10px);
            }
        }
    }

    @mixin tab {
        &__btn {
            bottom: unset;
            top: -8px;
        }
        
        &__slider {
            overflow: visible !important;
        }

        &__slider-nav {
            width: 100%;
            margin-top: 30px;
            order: 1;
            justify-content: center;
        }

        &__search {
            input {
                padding: 0 50px 0 22px;
    
                &, &::placeholder {
                    font-size: 14px;
                    line-height: 42px;
                }
            }
    
            button {
                right: 4px;
                top: 4px;
                width: 36px;
                height: 36px;
            }
        }
    }

    @mixin tab-sm {
        &__wrapper {
            padding-top: 170px;
        }

        &__img {
            top: -221px;
        }

        &__title-box {
            max-width: unset;
            margin-left: 0;
        }

        &__subtitle {
            font-family: var(--font-sec);
        }
    }

    @mixin mob-sm {
        &__btn {
            padding: 0 10px;
        }
    }
}