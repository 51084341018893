.job-board {
    padding-top: 32px;
    position: relative;
    margin-bottom: -20px;

    &::before {
        content: '';
        position: absolute;
        left: -3%;
        top: -15%;
        width: vw(346);
        height: vw(1069);
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='608' fill='none'%3E%3Cpath stroke='%2334EDFF' d='M45.301 607.5H.5v-54.239h13.173c14.92 0 25.161-3.442 30.618-10.432l.001-.002c2.704-3.503 4.704-8.564 6.031-15.14 1.328-6.583 1.99-14.719 1.99-24.405 0-19.342-.48-37.944-1.44-55.872l-.5.026.5-.027c-.96-17.874-1.44-37.67-1.44-59.355 0-26.848 4.318-46.151 12.86-58.012 8.548-11.902 21.827-19.967 39.971-24.191l.387-.09v-3.623l-.387-.09c-18.144-4.224-31.423-12.289-39.971-24.191-8.542-11.894-12.86-31.198-12.86-58.012 0-21.651.48-41.447 1.44-59.356.96-17.928 1.44-36.53 1.44-55.872 0-9.686-.662-17.823-1.99-24.405-1.327-6.576-3.327-11.636-6.03-15.14-5.424-7.027-15.703-10.434-30.62-10.434H.5V.5h44.801c31.567 0 54.682 7.162 69.421 21.416 14.719 14.235 22.156 40.327 22.156 78.457 0 12.724-.343 24.029-1.061 33.882a912.555 912.555 0 0 1-2.535 28.615 1075.397 1075.397 0 0 0-2.537 27.598c-.72 9.211-1.098 19.463-1.098 30.791 0 6.191 1.108 12.378 3.284 18.557 2.184 6.202 5.685 11.787 10.53 16.787 4.858 5.014 11.263 9.058 19.231 12.143 7.871 3.048 17.466 4.832 28.808 5.335v59.838c-11.342.503-20.937 2.287-28.808 5.335-7.968 3.085-14.373 7.129-19.231 12.143-4.845 5-8.346 10.619-10.53 16.786v.001c-2.176 6.179-3.284 12.366-3.284 18.557 0 11.328.378 21.58 1.098 30.791.72 9.198 1.543 18.398 2.537 27.598a912.555 912.555 0 0 1 2.535 28.615c.718 9.887 1.061 21.192 1.061 33.882 0 38.13-7.437 64.222-22.156 78.457-14.74 14.254-37.82 21.416-69.42 21.416Z'/%3E%3C/svg%3E") center / contain no-repeat;
    }

    .container {
        position: relative;
        z-index: 5;
    }

    &__wrapper {
        display: flex;
        align-items: flex-start;
        gap: 54px;
        justify-content: center;
        position: relative;
        z-index: 2;
    }

    &__filters {
        max-width: 340px;
        width: 100%;
        padding: 25px ac(44px, 20px) 0 20px;
        border: 1px solid var(--cyan);
        border-radius: 18px;
        background: var(--white);
        overflow: hidden;

        display: flex;
        flex-direction: column;
        gap: 35px;

        .filter-box.open {
            &:nth-child(3), &:nth-child(4) {
                .filter-box__head {
                    margin-bottom: 28px;
                }
            }
        }
    }

    &__pagination {
        margin-top: 60px;

        .pagination__list {
            margin-right: 26px;
        }
    }

    &__result {
        max-width: 844px;
        flex-grow: 1;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }

    &__decors {
        .decor-1, .decor-2 {
            position: absolute;
        }

        .decor-1 {
            width: vw(118);
            height: vw(374);

            max-width: 118px;
            max-height: 374px;
            bottom: -16px;
            left: 58px;
            z-index: 0;
        }

        .decor-2 {
            width: vw(176);
            height: vw(176);

            max-width: 176px;
            max-height: 176px;
            bottom: 17px;
            left: 125px;
            z-index: 0;
        }
    }

    .not-found {
        font-size: 28px;
        font-weight: 600;
        grid-column: -1 / 1;
        text-align: center;
        padding-top: 20px;
    }

    @mixin tab-md {
        &__wrapper {
            flex-direction: column;
        }

        &__filters, &__result {
            max-width: 100%;
            width: 100%;
        }

        &__decors {
            display: none;
        }
    }

    @mixin mob-xl {
        padding-top: 0;
    }

    @mixin mob-lg {
        &__list {
            grid-template-columns: 1fr;
        }
    }
}

.filter-box {
    padding-bottom: 30px;
    border-bottom: 1px solid var(--cyan);

    &:last-child {
        border: none
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        transition: margin 0.3s ease;
        cursor: pointer;

        h3 {
            font-weight: 600;
            font-size: 24px;
            font-family: var(--font-sec);
            transition: color 0.3s ease;
        }

        &:hover {
            h3 {
                color: var(--caribbean);
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 28px;
        height: 0;
        opacity: 0;
        transition:  0.3s ease;
        pointer-events: none;
    }

    &__icon {
        width: 20px;
        height: 20px;
        transition: transform 0.3s ease;
        transform: rotate(-180deg);

        background-repeat: no-repeat;
        background-size: 70%;
        background-position: center;
        background-image: url('data:image/svg+xml,<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.99999 2.828L2.04999 7.778L0.635986 6.364L6.99999 0L13.364 6.364L11.95 7.778L6.99999 2.828Z" fill="%23020519"/></svg>');
    }

    &.open {
        .filter-box__icon {
            transform: rotate(0deg);
        }

        .filter-box__content {
            opacity: 1;
            pointer-events: auto;
        }

        .filter-box__head {
            margin-bottom: 38px;
        }
    }
}