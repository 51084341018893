.hero {
    position: relative;
    margin-top: 164px;
    z-index: 1;

    &--about {
        .hero__wrapper {
            padding: ac(135px, 125px) ac(52px, 20px) ac(311px, 200px);
            overflow: visible;
        }

        .hero__decor {
            width: ac(533px, 200px);
            height: ac(533px, 200px);
            right: ac(117px, 30px);
            bottom: ac(-74px, 25px);
            transform: unset;
            z-index: 2;
        }

        .hero__title {
            max-width: 990px;

            &:before {
                background: var(--yellow-green);
            }

            span {
                position: relative;
                display: inline-flex;
                right: ac(14px, 4px);
                bottom: 1px;


                &::after,  &::before {
                    background: var(--light-blue);
                }

            }
            @media only screen and (min-width: 420px) and (max-width: 430px){
               h1{
                   font-size: 38px;
               }
                span{
                    margin-left: 0;
                }
            }
        }
    }

    &--board {
        z-index: 10;

        .hero__bg {
            &:before {
                display: none;
            }
        }

        .hero__wrapper {
            padding: 144px * 135px ac(50px, 20px);
        }

        .hero__title {
            padding-left: ac(37px, 20px);

            h1 span {
                margin-left: ac(-13px, 0px);

                &::after,  &::before {
                    background: var(--light-blue);
                }
            }

            &:before {
                background: var(--yellow-green);
            }
        }

        .hero__form {
            display: flex;
            gap: 16px;
            justify-content: center;
            transform: translateY(-50%);

            .input-item {
                width: 100%;

                &:first-of-type {
                    max-width: 502px;
                }

                &:nth-of-type(2) {
                    max-width: 320px;
                }
            }
        }
    }

    &--banner {
        .hero__wrapper {
            background: var(--cyan);
            padding: 68px ac(50px, 30px) 80px ac(88px, 40px);
            border-radius: 30px;
            min-height: auto !important;
        }

        h1 {
            span {
                position: static;
                display: inline-flex;

                &::after,  &::before {
                    background: var(--light-blue);
                }
            }
        }

        .btn {
            border: 1px solid transparent;

            &:hover {
                border-color: var(--white);
            }
        }

        .hero__heading {
            color: var(--white);
            position: relative;

            &:before {
                background: var(--yellow-green);
                left: ac(-38px, -22px);
                top: 12px;
            }

            &.aos-animate {
                &:before {
                    height: 67%;
                }
            }

            h1 {
                font-weight: 600;
                font-size: ac(60px, 38px);
                margin-bottom: ac(5px, 22px);
            }
        }

        .hero__details {
            margin-bottom: 36px;
            display: flex;
            flex-wrap: wrap;
            row-gap: 25px;
            column-gap: 27px;

            li {
                font-family: var(--font-sec);
                font-size: ac(22px, 18px);
                font-weight: 500;
                padding-left: 0;
                margin-bottom: 0 !important;

                &:first-child {
                    width: 100%;
                }

                span {
                    font-size: 32px;
                    font-weight: 700;
                    color: var(--yellow-green);
                }

                &:after, &:before {
                    display: none;
                }
            }
        }

        .hero__buttons {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 30px;
            align-items: flex-end;
        }
    }

    &__subtitle {
        margin-top: ac(29px, 15px);
        max-width: 477px;
        font-size: ac(24px, 20px);
        line-height: ac(28px, 24px);
        color: var(--white);
    }

    &__wrapper {
        position: relative;
        padding: ac(183px, 175px) ac(71px, 20px) 281px;
        overflow: hidden;
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--tealish);
            opacity: .3;
        }
    }

    &__decor {
        position: absolute;
        mix-blend-mode: color;
        width: ac(413px, 297px);
        height: ac(451px, 325px);
        right: ac(-36px, -50px);
        bottom: 50%;
        transform: translateY(calc(50% + ac(65px, 167px)));

        img {
            object-fit: contain;
        }
    }

    &__title {
        max-width: 650px;
        color: var(--white);
        padding-left: ac(31px, 20px);

        &:before {
            background: var(--light-blue);
        }
    }

    @mixin tab {
        margin-top: 76px;

        &__wrapper:not(.hero--banner .hero__wrapper) {
            width: calc(100% + 40px);
            margin-left: -20px;
        }
    }

    @mixin mob-xl {
        &--board {
            .hero__form {
                flex-direction: column;

               .input-item {
                   &:first-of-type,  &:nth-of-type(2) {
                       max-width: 100%;
                       width: 100%;
                   }
               }
            }

        }
    }

    @mixin mob-lg {
        &__wrapper {
            &:not(.hero--board .hero__wrapper) {
                min-height: calc(100vh - 78px);
            }
        }
    }
}