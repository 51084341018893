.values {
    margin: ac(132px, 60px) 0 -18px;

    &--about {
        .container {
            &:before {
                content: '';
                position: absolute;
                width: vw(260);
                height: vw(839);

                max-width: 260px;
                max-height: 839px;

                z-index: -1;
                left: vw(-110);
                top: -45%;

                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url('data:image/svg+xml,<svg width="260" height="839" viewBox="0 0 260 839" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M198.654 838.5H259.5V763.274H241.485C221.295 763.274 207.496 758.525 200.16 748.948L200.158 748.946C196.521 744.144 193.821 737.193 192.027 728.133C190.232 719.068 189.338 707.854 189.338 694.495C189.338 667.808 189.987 642.143 191.287 617.406C192.586 592.736 193.235 565.415 193.235 535.489C193.235 498.428 187.389 471.739 175.787 455.322L175.786 455.322C164.179 438.851 146.154 427.704 121.555 421.869L121.171 421.778V421.382V417.478V417.083L121.555 416.992C146.154 411.156 164.179 400.009 175.786 383.539C187.389 367.075 193.235 340.386 193.235 303.371C193.235 273.492 192.586 246.171 191.287 221.454C189.987 196.718 189.338 171.052 189.338 144.365C189.338 131.006 190.232 119.793 192.027 110.727C193.821 101.667 196.521 94.717 200.158 89.9147C207.449 80.2881 221.297 75.5868 241.485 75.5868H259.5V0.5H198.654C155.888 0.5 124.534 10.3866 104.527 30.103C84.5382 49.8018 74.4675 85.8774 74.4675 138.509C74.4675 156.07 74.9314 171.674 75.9048 185.278L75.9048 185.278C76.8789 198.937 78.0385 212.086 79.3373 224.77C80.637 237.462 81.7975 250.156 82.7723 262.849C83.7479 275.553 84.2587 289.696 84.2587 305.323C84.2587 313.842 82.7631 322.357 79.8229 330.864C76.875 339.395 72.1493 347.078 65.606 353.96C59.0497 360.855 50.3993 366.423 39.6267 370.674C28.9517 374.886 15.9204 377.349 0.5 378.031V460.969C15.9204 461.651 28.9517 464.114 39.6267 468.326C50.3993 472.577 59.0497 478.145 65.606 485.04C72.1498 491.922 76.875 499.652 79.8226 508.135L79.8229 508.136C82.7631 516.643 84.2587 525.158 84.2587 533.677C84.2587 549.304 83.7479 563.447 82.7723 576.151C81.7976 588.843 80.6835 601.536 79.3373 614.23C78.0385 626.914 76.8789 640.063 75.9048 653.722C74.9314 667.372 74.4675 682.977 74.4675 700.491C74.4675 753.123 84.5382 789.198 104.527 808.897C124.534 828.613 155.842 838.5 198.654 838.5Z" stroke="%2334EDFF"/></svg>');
            }
        }
    }

    &__title {
        display: inline-flex;
        padding-left: ac(37px, 16px);
        margin-left: ac(40px, 0px);

        span {
            right: ac(-16px, -12px);
        }

        &:before {
            background: var(--yellow-green);
        }
    }

    &__decor {
        position: absolute;
        background-position: center bottom;
        background-size: contain;
        background-repeat: no-repeat;

        &:nth-child(1) {
            right: -10px;
            top: ac(-13px, -46px);
            width: ac(185px, 74px);
            height: ac(350px, 142px);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='184' height='350' fill='none'%3E%3Cpath fill='%2334EDFF' d='M184 349.972H0v-151.23C0 147.77 10.856 107.571 32.567 78.09 54.28 48.61 91.51 22.59 144.234 0L184 73.561c-32.423 14.881-54.854 29.678-67.265 44.418-12.411 14.741-19.322 32.154-20.733 52.267h87.969V350l.029-.028Z'/%3E%3C/svg%3E");
        }

        &:nth-child(2) {
            right: ac(88px, 26px);
            top: ac(261px, 65px);
            width: ac(176px, 71px);
            height: ac(176px, 71px);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='176' height='176' fill='none'%3E%3Cpath fill='%23020519' d='M176 64.5v47h-64.5V176h-47v-64.5H0v-47h64.5V0h47v64.5H176Z'/%3E%3C/svg%3E");
        }
    }

    &__wrapper {
        position: relative;
        margin-top: ac(36px, 39px);
        max-width: ac(863px, 790px, 1024, 1440);
        margin-left: ac(146px, 50px, 1024, 1440);
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__icon {
        width: 100%;
        max-width: 40px;
        height: 40px;

        img {
            object-fit: contain;
            object-position: left;
        }
    }

    &__name {
        margin-top: ac(22px, 18px);
        font-family: var(--font-sec);
        font-size: 18px;
        font-weight: 600;
    }

    &__descr {
        margin-top: ac(12px, 13px);
        max-height: ac(162px, 175px);
        padding-right: 15px;
        width: calc(100% + 15px);

        .simplebar-scrollbar::before {
            background-color: var(--cyan);
            color: var(--cyan);
            opacity: 1!important;
            left: 0;
            right: 0;
        }

        .simplebar-track.simplebar-vertical{
            background: rgba(0,0,0, 0.1)!important;
            opacity: 1!important;
            width: 4px;
            border-radius:0;
            right: 5px;
        }
    }

    @media (min-width: 1024px) {
        .swiper-wrapper {
            gap: 32px 42px;
            display: flex;
            flex-wrap: wrap;
        }

        .swiper-slide {
            width: calc(100% / 2 - 42px);
        }
    }

    @mixin tab {
        &__wrapper {
            max-width: unset;
            margin-left: 0;
        }

        &__slider-nav {
            margin-top: 30px;
            justify-content: center;
        }

        &__slider {
            overflow: visible !important;
        }

        &__descr {
            line-height: 19px;
        }

        &__decor {
            display: none;
        }
    }

    @mixin mob-sm {
        &__decor {
            &:nth-child(1) {
                height: 60px;
                width: 34px;
                right: 0;
                top: 32px;
            }
    
            &:nth-child(2) {
                height: 25px;
                right: 15px;
                top: 75px;
                width: 25px;
            }
        }
    }
}