.about-us {
    position: relative;
    margin-top: ac(142px, 60px);

    &--about {
        margin-top: ac(150px, 60px);

        .about-us__wrapper {
            padding: 0;
        }

        .about-us__text {
            margin-bottom: 0;
            padding-left: ac(29px, 16px);

            &:before {
                background: var(--caribbean);
            }
        }

        .about-us__subtitle {
            margin-bottom: 18px;
            max-width: ac(600px, 400px, 768, 1440);
        }

        .about-us__descr {
            max-width: ac(520px, 360px, 768, 1440);
            line-height: 22px;
        }

        .about-us__img {
            right: ac(85px, 20px, 768, 1440);
            top: -30px;
            width: ac(431px, 270px);
            height: ac(514px, 351px);
    
            img {
                filter: unset;
            }
          
            &::before {
                content: '';
                position: absolute;
                bottom: ac(-57px, -25px);
                right: ac(-47px, -20px);
                width: ac(301px, 150px);
                height: ac(342px, 160px);
                background-color: var(--caribbean);
                z-index: 1;
                mix-blend-mode: color;
            }
        }

        .about-us__decor {
            top: ac(-430px, -274px);
            left: ac(-82px, -50px);
            width: ac(260px, 170px);
            height: ac(839px, 551px);
        }
    }

    &__decor {
        position: absolute;
        top: ac(-502px, -274px);
        left: ac(-158px, -50px);
        width: ac(364px, 170px);
        height: ac(1173px, 551px);
        background: url("data:image/svg+xml,%3Csvg width='364' height='1173' viewBox='0 0 364 1173' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M83.6171 1046.98L83.6188 1046.98C88.6853 1040.3 92.4575 1030.61 94.9661 1017.96C97.4757 1005.3 98.7274 989.639 98.7274 970.969C98.7274 933.661 97.8177 897.782 95.9982 863.202C94.1797 828.707 93.2703 790.506 93.2703 748.664C93.2703 696.837 101.456 659.472 117.735 636.468L117.736 636.467C134.02 613.391 159.303 597.785 193.777 589.618L194.161 589.527V589.132V583.673V583.278L193.777 583.187C159.303 575.02 134.02 559.414 117.736 536.338C101.456 513.268 93.2703 475.903 93.2703 424.141C93.2703 382.364 94.1797 344.164 95.9982 309.603C97.8177 275.023 98.7274 239.144 98.7274 201.836C98.7274 183.166 97.4757 167.503 94.9661 154.846C92.4575 142.193 88.6853 132.509 83.6188 125.829C73.4692 112.446 54.164 105.876 25.9211 105.876H0.5V0.5H85.884C145.782 0.5 189.74 14.3278 217.802 41.9452C245.847 69.5449 259.945 120.053 259.945 193.648C259.945 218.203 259.296 240.025 257.933 259.05C256.569 278.149 254.945 296.534 253.127 314.27C251.307 332.013 249.683 349.758 248.318 367.503C246.953 385.258 246.238 405.026 246.238 426.87C246.238 438.757 248.327 450.639 252.437 462.514C256.554 474.412 263.155 485.13 272.297 494.732C281.452 504.347 293.536 512.117 308.596 518.051C323.559 523.947 341.843 527.391 363.5 528.332V644.668C341.843 645.609 323.559 649.053 308.596 654.949C293.536 660.883 281.452 668.653 272.297 678.268C263.154 687.87 256.554 698.653 252.437 710.485L252.437 710.486C248.327 722.361 246.238 734.243 246.238 746.13C246.238 767.974 246.953 787.742 248.318 805.497C249.683 823.241 251.242 840.986 253.127 858.732C254.945 876.467 256.569 894.851 257.933 913.95C259.296 933.04 259.945 954.862 259.945 979.352C259.945 1052.95 245.847 1103.46 217.802 1131.05C189.74 1158.67 145.847 1172.5 85.884 1172.5H0.5V1066.93H25.9211C54.1666 1066.93 73.4035 1060.29 83.6171 1046.98Z' stroke='%2334EDFF'/%3E%3C/svg%3E%0A") center / contain no-repeat;
        z-index: -1;
    }

    &__img {
        position: absolute;
        right: ac(-47px, -20px, 1024, 1440);
        top: ac(-193px, -70px, 1024, 1440);
        width: ac(351px, 267px);
        height: ac(350px, 269px);
        z-index: 2;

        img {
            filter: grayscale(1);
        }

        &:nth-child(2) {
            top: ac(104px, 190px, 1024, 1440);
            right: ac(109px, 130px, 1024, 1440);
            width: ac(304px, 208px);
            height: ac(450px, 307px);

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: ac(208px, 140px);
                height: ac(405px, 276px);
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='208' height='405' fill='none'%3E%3Cpath fill='%2334EDFF' d='M208 404.967H0V229.973c0-58.982 12.272-105.498 36.815-139.611C61.358 56.248 103.447 26.138 163.047 0L208 85.121c-36.652 17.219-62.009 34.341-76.039 51.398-14.029 17.057-21.841 37.206-23.436 60.48h99.442V405l.033-.033Z' style='mix-blend-mode:color'/%3E%3C/svg%3E") center / contain no-repeat;
                mix-blend-mode: color;
                z-index: 1;
            }
        }
    }

    &__wrapper, &__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__wrapper {
        position: relative;
        padding: 0 ac(71px, 20px);
    }

    &__text {
        margin-bottom: ac(40px, 30px);
        padding-left: ac(30px, 16px);

        &:before {
            background: var(--yellow-green);
        }
    }

    &__title {
        margin-bottom: ac(26px, 20px);
    }

    &__subtitle {
        font-family: var(--font-alt);
        margin-bottom: 16px;
        max-width: ac(736px, 600px, 1024, 1440);
        color: #020519;
        font-size: ac(21px, 17px);
        line-height: ac(24px, 20px);
        font-weight: 700;
    }

    &__descr {
        max-width: ac(520px, 500px, 1024, 1440);
    }

    &__btn {
        background-color: var(--white);
        margin-left: ac(37px, 22px);
    }

    @mixin tab {
        &--about {
            .about-us__wrapper {
                width: 100%;
                margin-left: 0;
            }
        }

        &__wrapper {
            width: calc(100% + 40px);
            margin-left: -20px;
        }

        &__subtitle {
            font-weight: 700;
            font-family: var(--font-main);
            letter-spacing: .5px;
        }

        &__img {
            right: 0;
            top: unset;
            bottom: ac(-100px, -300px, 375, 1024);
    
            &:nth-child(2) {
                top: unset;
                bottom: ac(-350px, -522px, 375, 1024);
                right: ac(240px, 137px, 375, 1024);
            }
        }
    }

    @mixin tab-sm {
        &--about {
            .about-us__descr, .about-us__subtitle {
                max-width: unset;
            }

            .about-us__img {
                position: relative;
                right: unset;
                bottom: unset;
                top: unset;
                margin: 0 auto 25px;
            }
        }
    }
}