/* Cookie popup*/
.termsfeed-com---palette-dark.termsfeed-com---nb {
    background-color: var(--white) !important;
    color: var(--cyan) !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);

}

.termsfeed-com---reset {
    font-family: var(--font-main) !important;
    overflow-x: hidden;

    * {
        font-family: var(--font-main) !important;
    }

    .cc-nb-buttons-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        button {
            margin: 0 !important;
        }
    }
}

.termsfeed-com---palette-dark .cc-nb-title, .termsfeed-com---palette-dark .cc-nb-text {
    color: inherit !important;
}

.termsfeed-com---palette-dark .cc-nb-okagree, .termsfeed-com---palette-dark .cc-nb-reject, .termsfeed-com---palette-dark .cc-cp-foot-save, .termsfeed-com---palette-dark .cc-nb-changep {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 ac(23px, 10px);
    border: 2px solid var(--cyan);
    border-radius: 25px;
    transition: .3s ease !important;
    height: 47px;
    background: var(--white);
    font-size: ac(17px, 15px) !important;
    font-family: var(--font-sec) !important;

    &:hover {
        background: var(--cyan);
        color: var(--white);
    }
}


.termsfeed-com---palette-dark .cc-pc-head {
    background: var(--cyan) !important;
    color: var(--white) !important;
    border-bottom: 1px solid var(--persimmon) !important;
}

.termsfeed-com---palette-dark .cc-pc-head-title-headline {
    color: inherit !important;
}

.termsfeed-com---palette-dark .cc-pc-head-close {
    color: inherit !important;
    border-color: var(--black);

    &:hover {
        color: var(--persimmon) !important;
    }
}

.termsfeed-com---palette-dark .cc-cp-foot {
    border-top-color: var(--persimmon) !important;
}

.termsfeed-com---pc-dialog input[type=checkbox].cc-custom-checkbox:checked + label:before {
    background: var(--cyan) !important;
}

.termsfeed-com---palette-dark .cc-pc-head-lang select {
    border: 1px solid var(--white) !important;
    color: var(--white) !important;
    background: var(--cyan);
}

.termsfeed-com---palette-dark .cc-cp-body-tabs-item[active=true] button {
    color: var(--white) !important;
    background: var(--cyan) !important;

}

.termsfeed-com---pc-dialog .cc-cp-body-content-entry[active=true], .termsfeed-com---palette-dark .cc-cp-foot {
    background: var(--white) !important;
    color: var(--cyan) !important;
}

.termsfeed-com---palette-dark .cc-cp-body-tabs-item, .termsfeed-com---palette-dark .cc-cp-body-tabs {
    background: var(--apricot) !important;
}

.termsfeed-com---pc-dialog .cc-cp-body-tabs-item {
    border-color: var(--cyan) !important;

    &::before, &::after {
        display: none;
    }
}

.termsfeed-com---palette-dark .cc-cp-body-tabs .cc-cp-body-tabs-item[active=true] {
    background: var(--persimmon) !important;
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry-title, .termsfeed-com---palette-dark .cc-cp-body-content-entry-text {
    color: inherit !important;
}

.termsfeed-com---palette-dark .cc-cp-body .cc-cp-body-content {
    background: var(--white) !important;
}

.termsfeed-com---palette-dark .cc-cp-foot-byline {
    color: var(--black) !important;
}

.termsfeed-com---palette-dark .cc-pc-container .cc-cp-body {
    background: var(--white) !important;
    color: var(--black) !important;
}

.termsfeed-com---pc-dialog .cc-cp-foot-byline a {
    color: var(--persimmon) !important;
    display: inline;
}

.termsfeed-com---palette-dark .cc-cp-body-tabs-item-link {
    color: var(--cyan) !important;
    font-size: 16px !important;

    &:hover {
        background: var(--white);
        color: var(--cyan) !important;
    }
}

.termsfeed-com---palette-dark .cc-pc-head-lang select:focus {
    box-shadow: 0 0 0 2px var(--cyan) !important;
}

.termsfeed-com---palette-dark .cc-cp-body-tabs-item-link:focus {
    box-shadow: none !important;
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry a {
    color: var(--persimmon) !important;
    display: inline !important;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.termsfeed-com---pc-overlay .termsfeed-com---pc-dialog .cc-cp-body-tabs-item-link {
    font-size: 16px;
}

@media only screen and (max-width: 320px) and (min-height: 480px) {
    .termsfeed-com---nb-simple {
        height: auto !important;
    }
}

.termsfeed-com---palette-dark .cc-nb-okagree:focus {
    box-shadow: none !important;
}

/* Cookie popup end*/