@font-face {
    font-family: 'Bierstadt Display';
    src: url('../fonts/bierstadt_display.woff2') format('woff2'),
         url('../fonts/bierstadt_display.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bierstadt Display';
    src: url('../fonts/bierstadt_display_bold.woff2') format('woff2'),
         url('../fonts/bierstadt_display_bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bierstadt';
    src: url('../fonts/bierstadt_bold.woff2') format('woff2'),
         url('../fonts/bierstadt_bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bierstadt';
    src: url('../fonts/bierstadt_italic.woff2') format('woff2'),
         url('../fonts/bierstadt_italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bierstadt';
    src: url('../fonts/bierstadt_regular.woff2') format('woff2'),
         url('../fonts/bierstadt_regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?m50bqv');
    src:  url('../fonts/icomoon.eot?m50bqv#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?m50bqv') format('truetype'),
    url('../fonts/icomoon.woff?m50bqv') format('woff'),
    url('../fonts/icomoon.svg?m50bqv#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-linked-in:before {
    content: "\e900";
}
.icon-instagram-light:before {
    content: "\e901";
}
.icon-fb:before {
    content: "\e902";
}
.icon-inst:before {
    content: "\e903";
}
.icon-tw:before {
    content: "\e904";
}


h1,h2,h3,h4,h5,h6 {
    text-wrap: balance;
}

h1 {
    position: relative;
    font-family: var(--font-sec);
    font-size: ac(80px, 40px);
    line-height: ac(97px, 48px);
    font-weight: 600;
    width: fit-content;

    span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;

        width: 13px;
        height: 13px;

        &::after,  &::before {
            content: '';
            position: absolute;
            background-color: var(--yellow-green);
        }
    
        &::before {
            width: 13px;
            height: 3px;
        }
    
        &::after {
            width: 3px;
            height: 13px;
        }
    }

}

h2 {
    position: relative;
    font-family: var(--font-sec);
    font-size: ac(50px, 30px);
    line-height: ac(60px, 36px);
    font-weight: 600;

    span {
        position: absolute;
        right: -12px;
        bottom: ac(12px, 7px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8px;
        height: 8px;

        &::after,  &::before {
            content: '';
            position: absolute;
            background-color: var(--cyan);
        }
    
        &::before {
            width: 8px;
            height: 2px;
        }
    
        &::after {
            width: 2px;
            height: 8px;
        }
    }
}

a {
    display: inline-block;
    transition: .3s ease;

    &:hover {
        color: var(--cyan);
    }
}

ul {
    li {
        position: relative;
        padding-left: 20px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        &::after,  &::before {
            content: '';
            position: absolute;
            background-color: var(--yellow-green);
            transition: .3s ease;
        }
    
        &::before {
            left: 0;
            top: 9px;
            width: 8px;
            height: 2px;
        }
    
        &::after {
            left: 3px;
            top: 6px;
            width: 2px;
            height: 8px;
        }
    }
}

.content-element {
    h2,h3,h4,h5,h6 {
        margin: 3px 0 22px;
        font-weight: 600;
        font-family: var(--font-sec);

        position: relative;
        display: flex;
        align-items: center;
        line-height: 140%;

        &:before {
            content: '+';
            font-size: 32px;
            font-weight: 700;
            color: var(--caribbean);
            margin: 0 7px 3px 0;
        }
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 22px;
    }

    h4 {
        font-size: 21px;
    }

    h5 {
        font-size: 20px;
    }

    h6 {
        font-size: 18px;
    }

    p, li, a {
        font-size: 18px;
        line-height: 144%;
    }

    a {
        color: var(--cyan);

        &:hover {
            text-decoration: underline;
        }
    }

    p {
        &:not(:last-of-type) {
            margin-bottom: 30px;
        }
    }

    li {
        &:not(:last-of-type) {
            margin-bottom: 3px;
        }
    }

    ol {
        list-style: decimal;
    }

    ol, ul {
        padding-left: 27px;

        &:not(:last-child) {
            margin-bottom: 27px;
        }
    }

    ul {
        list-style: disc;

        li {
            padding: 0;
            line-height: 140%;

            &:before, &:after {
                display: none;
            }
        }
    }
}