.employers {
    padding: ac(297px, 110px) 0 155px;
    margin-top: ac(-157px, 0px);
    background-color: var(--caribbean);

    &__wrapper, &__title-box {
        display: flex;
        align-items: flex-start;
    }

    &__title-box {
        flex: 0 0 auto;
        margin-right: ac(128px, 35px, 1024, 1440);
        flex-direction: column;
        max-width: ac(628px, 480px, 1024, 1440);
        padding-left: ac(30px, 16px);

        &:before {
            background: var(--cyan);
        }
    }

    &__title {
        margin-bottom: ac(26px, 20px);
    }

    &__subtitle {
        font-family: var(--font-alt);
        margin-bottom: ac(16px, 18px);
        color: #020519;
        font-size: ac(21px, 17px);
        line-height: ac(24px, 20px);
        font-weight: 700;
    }

    &__descr {
        max-width: 520px;
    }

    &__col {
        max-width: 510px;
        margin-top: ac(86px, 41px);

        li {
            font-size: 17px;
            line-height: 20px;
        }
    }

    &__btn {
        margin-top: ac(40px, 31px);
        border-color: var(--tealish);

        &:hover {
            border-color: var(--cyan);
        }
    }

    @mixin tab {
        &__wrapper {
            flex-direction: column;
        }

        &__title-box {
            margin-right: 0;
            max-width: unset;
        }

        &__descr, &__col {
            max-width: unset;

            br {
                display: none;
            }
        }

        &__col {
            .employers__subtitle {
                letter-spacing: 0.18px;
                font-family: var(--font-sec);
                font-weight: 700;
            }
        }
    }

    @mixin tab-sm {
        padding-bottom: ac(100px, 222px);
    }
}