.header {
	position: fixed;
    top: 0;
    left: 0;
	width: 100%;
	padding: 50px 0;
	transition: 0.3s ease;
	z-index: 50;

	&.sticky {
		background-color: var(--white);
	}

    &__layout {
		position: absolute;
		width: 100%;
		height: 100vh;
		bottom: -1px;
		transform: translateY(100%);
		background-color: var(--white);
		visibility: hidden;
		opacity: 0;
		transition: 0.3s ease;
		z-index: -1;
		cursor: pointer;
	}

    &__wrapper, &__navbar {
        display: flex;
        align-items: center;
    }

    &__logo {
		position: relative;
        width:ac(85px, 64px);

        img {
            object-fit: contain;
			transition: .3s ease;

			/*&:nth-child(2) {
				top: 0;
				left: 0;
				position: absolute;
				opacity: 0;
			}*/
        }

		&:hover {
			img {
				transform: scale(1.05);
				/*&:nth-child(1) {
					opacity: 0;
				}*/
				
				/*&:nth-child(2) {
					opacity: 1;
				}*/
			}
		}
    }

    &__navbar-box {
		margin-left: auto;
        margin-right: ac(41px, 30px, 1024, 1440);
	}

    &__link {
		position: relative;
        font-size: 17px;
        font-weight: 600;
        font-family: var(--font-sec);

		&:not(:last-child) {
			margin-right: ac(25px, 20px, 1024, 1440);
		}
	}

	@media (min-width: 1024px) {
		&.sticky {
			padding: 10px 0;
			border-bottom: 1px solid var(--tealish);
	
			.header__logo {
				width:ac(85px, 64px);
			}
		}
	}

    @mixin tab {
        padding: 16px 0;

        &.active {
			.header__navbar-box {
				transform: translate(0%, 100%);
				opacity: 1;
				visibility: visible;
			}

			.header__layout {
				opacity: 0.3;
				visibility: visible;
			}

			.header__burger {
				span {
					&:nth-child(1) {
						transform: rotate(45deg) translate(5px, 5px);
					}

					&:nth-child(2) {
						opacity: 0;
						width: 0;
					}

					&:nth-child(3) {
						transform: rotate(-45deg) translate(5px, -5px);
					}
				}
			}
		}

		&__bottom {
			margin-top: auto;
			font-size: 15px;
			text-align: center;
			opacity: .5;
		}

        &__simplebar {
			height: 100%;
			max-height: calc(100% - 75px);
			padding-right: 20px;
			padding-left: 20px;
			width: calc(100% + 20px);
		}

		.simplebar-track {
			width: 10px;
			border-radius: 20px;
			cursor: pointer;
		}

		.simplebar-content {
			height: 100%;
		}

		.simplebar-content-wrapper {
			padding: 0 ac(30px, 15px) 0 0;
		}

		.simplebar-scrollbar::before {
			background-color: var(--cyan);
			opacity: 1;
		}

        &__burger {
            padding: 5px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
            margin-left: auto;
			width: 44px;
			height: 44px;
			border: 1px solid var(--cyan);
			border-radius: 100%;
			cursor: pointer;
			transition: .3s ease;

			span {
                width: 18px;
                height: 2px;
				background-color: var(--tealish);
				transition: .3s ease;

				&:nth-child(2) {
					margin: 5px 0;
				}
			}

			&:hover {
				background-color: var(--cyan);

				span {
					background-color: var(--white);
				}
			}
		}

        &__navbar-box {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			height: calc(80vh - 76px);
			height: calc((var(--vh, 1vh) * 80) - 76px);
			position: absolute;
			right: 0;
			bottom: 0;
            margin: 0;
			padding: 3.5vh 10px;
			transform: translate(100%, 100%);
			opacity: 0;
			background-color: var(--white);
			visibility: hidden;
			transition: 0.3s ease;
			overflow: hidden;

			&::before {
				content: '';
				position: absolute;
				right: -26px;
				top: 66px;
				width: 170px;
				height: 551px;
				background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='170' height='551' fill='none'%3E%3Cpath stroke='%2334EDFF' d='M40.11 550.5H.5v-49.06h11.606c13.22 0 22.312-3.122 27.158-9.477l.002-.002c2.4-3.183 4.172-7.775 5.348-13.738 1.176-5.968 1.761-13.344 1.761-22.124 0-17.529-.425-34.388-1.275-50.636-.849-16.198-1.273-34.138-1.273-53.789 0-24.33 3.823-41.813 11.378-52.55v-.001c7.56-10.774 19.304-18.075 35.357-21.9l.384-.092v-3.354l-.384-.091c-16.053-3.825-27.797-11.126-35.357-21.901-7.555-10.768-11.378-28.252-11.378-52.551 0-19.62.424-37.56 1.273-53.789.85-16.248 1.275-33.106 1.275-50.635 0-8.78-.585-16.156-1.761-22.125-1.176-5.962-2.948-10.555-5.348-13.738-4.817-6.388-13.943-9.478-27.16-9.478H.5V.5h39.61c27.94 0 48.387 6.488 61.423 19.391 13.018 12.886 19.604 36.517 19.604 71.072 0 11.531-.304 21.774-.94 30.703a844.05 844.05 0 0 1-2.244 25.931 999.901 999.901 0 0 0-2.246 25.012c-.638 8.348-.972 17.64-.972 27.907 0 5.615.981 11.225 2.909 16.829 1.936 5.627 5.039 10.694 9.334 15.23 4.307 4.55 9.986 8.22 17.048 11.018 6.964 2.761 15.45 4.378 25.474 4.837v54.14c-10.024.459-18.51 2.076-25.474 4.837-7.062 2.799-12.741 6.468-17.048 11.018-4.295 4.537-7.398 9.634-9.333 15.229l-.001.001c-1.928 5.604-2.909 11.214-2.909 16.829 0 10.267.334 19.559.972 27.907.637 8.336 1.366 16.674 2.246 25.012a844.323 844.323 0 0 1 2.244 25.931c.636 8.959.94 19.203.94 30.703 0 34.555-6.586 58.186-19.604 71.072C88.497 544.012 68.08 550.5 40.111 550.5Z'/%3E%3C/svg%3E") center / contain no-repeat;
			}
		}

		&__navbar {
			width: 100%;
			height: 90%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding-bottom: 2px;
		}

		&__link {
			font-size: 30px;
			line-height: 34px;
			font-weight: 700;
		
			margin-top: auto;
			margin-bottom: auto;

			&:not(:first-child) {
				padding-top: 15px;
			}

			&:not(:last-child) {
				margin-right: 0;
			}

			&:last-child {
				margin-bottom: 0;
				color: var(--cyan);

				&::before {
					content: '';
					position: absolute;
					left: 0;
					bottom: -2px;
					width: 100%;
					height: 2px;
					background-color: var(--cyan);
				}
			}
		}
    }


	@mixin mob-xl {
		&__navbar-box {
			max-width: unset;
			padding: 0 10px 3.5vh;
			height: calc(100vh - 76px);
			height: calc((var(--vh, 1vh) * 100) - 76px);
		}

		&__simplebar {
			height: auto;
			margin-top: auto;
		}

		&__link {
			margin-bottom: 5.7vh;
			
			&:not(:first-child) {
				padding-top: 0;
			}
		}
	}

	@media (max-width: 568px) and (max-height: 600px) {
		&__navbar-box {
			padding-top: 0vh;
		}
	}
}