.pagination {
    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__arrow {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 700;


        .icon {
            width: 24px;
            height: 24px;
            display: inline-block;
            transition: background-color 0.3s ease;

            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: 50%;
            background-color: var(--tealish);
            mask-image: url('data:image/svg+xml,<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.99999 2.828L2.04999 7.778L0.635986 6.364L6.99999 0L13.364 6.364L11.95 7.778L6.99999 2.828Z" fill="%23020519"/></svg>');
        }

        &:first-of-type {
            margin-right: ac(22px, 12px);

            .icon {
                transform: rotate(-90deg);
            }
        }

        &:last-of-type {
            margin-left: ac(15px, 12px);

            .icon {
                transform: rotate(90deg);
            }
        }

        &:hover {
            .icon {
                background-color: var(--cyan);
            }
        }
    }

    &__num {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.4;
        transition: 0.3s ease;
        flex-shrink: 0;

        span {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 148.8%;
            font-family: var(--font-sec);
            color: var(--cyan);
            position: relative;
        }

        &:not(:last-of-type) {
            margin-right: ac(30px, 12px);
        }

        &.active {
            border-radius: 52px;
            border: 2px solid var(--cyan);
            width: 52px;
            height: 52px;
            opacity: 1;
            margin-right: 12px !important;
        }

        &:not(.active) {
            &:hover {
                opacity: 1;
                transform: translateY(-5px);
            }
        }
    }
}