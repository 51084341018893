@import "https://fonts.googleapis.com/css2?family=Inter+Tight:wght@500;600&display=swap";

:root {
    --font-main: 'Bierstadt', sans-serif;
    --font-alt: 'Bierstadt Display', sans-serif;
    --font-sec: 'Inter Tight', sans-serif;

    --white: #FFFFFF;
    --tealish: #020519;
    --cyan: #1B76B9;
    --light-blue: #34EDFF;
    --yellow-green: #C7FD89;
    --caribbean: #0CCFAA;
}