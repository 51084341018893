.footer {
    position: relative;
    margin-top: ac(140px, 60px);

    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 322px;
        width: 100%;
        height: 1px;
        background-color: var(--tealish);
        z-index: -1;
    }

    &__wrapper, &__title-box, &__link-box, &__bottom, &__soc-box, &__copy-box {
        display: flex;
    }

    &__wrapper {
        flex-wrap: wrap;
        align-items: center;
        padding: ac(100px, 60px) ac(71px, 20px) ac(97px, 58px);
        background-color: var(--tealish);
    }

    &__title-box {
        flex: 0 0 auto;
        margin-right: ac(165px, 70px, 1024, 1440);
        flex-direction: column;
        align-items: flex-start;
        max-width: ac(628px, 370px, 768, 1440);
        padding-left: ac(37px, 16px);

        &:before {
            background: var(--cyan);
        }
    }

    &__title, &__subtitle, &__descr {
        color: var(--white);
    }

    &__title {
        margin-bottom: ac(26px, 20px);
    }

    &__subtitle {
        font-family: var(--font-alt);
        margin-bottom: 16px;
        font-size: ac(21px, 17px);
        line-height: ac(24px, 20px);
        font-weight: 700;
    }

    &__descr {
        max-width: 483px;

        &:not(:last-child) {
            margin-bottom: 7px;
        }
    }

    &__link-box {
        margin-top: ac(55px, 41px);
        flex-direction: column;
        align-items: flex-start;

        span {
            margin-bottom: ac(15px, 14px);
            font-family: var(--font-sec);
            font-size: ac(18px, 17px);
            color: var(--white);

            &:not(:first-child) {
                margin-top: ac(30px, 24px);
            }
        }

        a {
            font-size: ac(22px, 20px);
            color: var(--light-blue);

            &:hover {
                color: var(--cyan);
            }
        }
    }

    &__locations {
        margin-top: ac(52px, 45px);
        padding-left: ac(37px, 22px);
        color: var(--white);

        p {
            &:nth-child(1) {
                font-family: var(--font-sec);
                margin-bottom: ac(10px, 14px);
                font-size: 18px;
            }

            &:nth-child(2) {
                font-size: 15px;
                line-height: 22px;
            }
        }
    }
    
    &__bottom {
        margin-top: ac(40px, 42px);
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__logo {
        position: relative;
        margin-top: ac(12px, -1px);
        width:ac(85px, 64px);

        img {
            object-fit: contain;
			transition: .3s ease;

			/*&:nth-child(2) {
				top: 0;
				left: 0;
				position: absolute;
				opacity: 0;
			}*/
        }

		&:hover {
			img {
                transform: scale(1.05);
				/*&:nth-child(1) {
					opacity: 0;
				}
				
				&:nth-child(2) {
					opacity: 1;
				}*/
			}
		}
    }

    &__soc-box {
        align-self: flex-start;
        align-items: flex-end;
        a{
            font-size: 20px;
            margin-left:ac(27px, 20px);
            line-height: 1;
            display: inline-flex;
            align-items: flex-end;
            &:hover{
                color: var(--cyan);
            }
        }
    }

    &__soc {
        margin-left: ac(27px, 20px);
        width: 20px;
        height: 20px;
        background-position: center bottom;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__copy-box {
        width: 100%;
    }

    &__copy {
        opacity: 0.5;

        &:first-child {
            margin-left: auto;
            margin-right: 105px;
        }
    }

    @mixin tab-sm {
        &::before {
            bottom: 466px;
        }

        &__wrapper {
            width: calc(100% + 20px);
            margin-left: -10px;
        }

        &__title-box {
            max-width: unset;
            width: 100%;
        }

        &__descr {
            max-width: unset;
        }

        &__link-box {
            flex-wrap: wrap;
            margin-left: 22px;

            span {
                font-family: var(--font-sec);
            }
        }

        &__subtitle {
            font-family: var(--font-sec);
        }
    }

    @mixin mob-lg {
        &__copy-box {
            flex-direction: column;
            align-items: flex-end;
        }

        &__copy {
            &:first-child {
                margin-right: 0;
                margin-top: 15px;
                margin-bottom: 10px;
            }
        }
    }
}