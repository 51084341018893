.services {
    margin-top: 140px;

    &--about {
        position: relative;
        margin-top: 112px;
        background-color: var(--tealish);
        z-index: 1;

        .services__wrapper {
            padding: 119px 100px 119px 216px;

            &::before {
                content: '';
                top: 95px;
                left: 29px;
                width: 110px;
                height: 401px;
                position: absolute;

                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='110' height='401' fill='none'%3E%3Cpath fill='%2334EDFF' d='M11.245 206.912 2.168 62.682 0 0h64l-2.168 62.683-9.077 144.229h-41.51ZM64 304H0v-69.752h64V304Z'/%3E%3Cpath fill='%230CCFAA' d='M110 400.986H18v-75.615c0-25.486 5.428-45.585 16.284-60.326 10.855-14.74 29.471-27.751 55.833-39.045L110 262.781c-16.212 7.44-27.427 14.839-33.632 22.209-6.206 7.37-9.661 16.076-10.367 26.133h43.985V401l.014-.014Z'/%3E%3C/svg%3E");
            }
        }

        .services__title-box {
            max-width: 590px;
            padding-left: ac(29px, 16px);
            margin-bottom: ac(59px, 35px);

            &:before {
                background: var(--cyan);
                left: 0;
            }

            &.aos-animate:before {
                height: 96%;
            }
        }

        .services__title {
            &::after, &::before {
                span {
                    background-color: var(--yellow-green);
                }
            }
        }

        .services__subtitle {
            line-height: 23px;
        }

        .services__icon {
            height: 35px;
            margin-bottom: 22px;
        }

        .swiper-slide {
            &:nth-child(3n + 1) {
                .services__icon {
                    width: 18px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='35' fill='none'%3E%3Cpath fill='%230CCFAA' d='M18 34.997H0V19.874c0-5.097 1.062-9.117 3.186-12.065C5.31 4.861 8.952 2.259 14.11 0L18 7.356c-3.172 1.488-5.366 2.968-6.58 4.442-1.214 1.474-1.89 3.215-2.028 5.227h8.605V35l.003-.003Z'/%3E%3C/svg%3E");
                }
            }

            &:nth-child(3n + 2) {
                .services__icon {
                    width: 31px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='35' fill='none'%3E%3Cpath fill='%23C7FD89' d='M31 9.955 12.161 17.47 31 24.982V35L0 21.674v-8.372L31 0v9.958-.003Z'/%3E%3C/svg%3E");
                }
            }

            &:nth-child(3n + 3) {
                .services__icon {
                    width: 35px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='35' fill='none'%3E%3Cpath fill='%2334EDFF' d='M35 12.827v9.346H22.173V35h-9.346V22.173H0v-9.346h12.827V0h9.346v12.827H35Z'/%3E%3C/svg%3E");
                }
            }
        }

        .services__name {
            font-size: ac(20px, 18px);
        }

        .services__descr {
            font-size: 17px;
            line-height: 21px;
        }
    }

    &__wrapper {
        position: relative;
        padding: 119px 216px 119px 107px;
        background-color: var(--tealish);
    }

    &__slider--main {
        overflow: visible !important;
    }

    &__title-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 698px;
        margin-bottom: 51px;

        &::before {
            background-color: var(--caribbean);
            left: -37px;
        }
    }

    &__title, &__subtitle, &__name, &__descr {
        color: var(--white);
    }

    &__title {
        margin-bottom: 22px;

        span {
            &::after, &::before {
                background-color: var(--light-blue);
            }
        }

        &--sec {
            font-size: ac(24px, 20px);
            line-height: ac(28px, 25px);
            font-family: var(--font-sec);
            margin-bottom: ac(40px, 38px);
        }
    }

    &__bottom {
        display: flex;
        align-items: flex-end;
    }

    &__subtitle {
        margin-right: 30px;

        span {
            color: var(--light-blue);
        }
    }

    &__btn {
        flex: 0 0 auto;
        margin-bottom: 4px;
        border-color: var(--light-blue);

        span {
            color: var(--white);
        }

        &:hover {
            border-color: var(--cyan);
        }
    }

    &__card-box {
        max-width: 952px;
    }

    &:not(.services--about) {

    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__icon {
        height: 19px;
        margin-bottom: 17px;

        img {
            width: 100%;
            height: 100%;
            max-width: 35px;
            max-height: 35px;
            object-fit: contain;
            object-position: left;
        }
    }

    &__name {
        margin-bottom: ac(12px, 12px);
        font-size: 18px;
        font-family: var(--font-sec);
    }

    &__descr {
        font-size: 15px;
        line-height: ac(17px, 19px);

        max-height: 150px;
        padding-right: 10px;
        width: calc(100% + 10px);

		.simplebar-track {
			width: 8px;
			border-radius: 20px;
			cursor: pointer;
		}

		.simplebar-scrollbar::before {
			background-color: var(--white);
			opacity: 1;
		}
    }

    &__img {
        position: absolute;
        bottom: ac(-117px, -50px, 1024, 1440);
        right: -46px;
        max-width: ac(535px, 288px);
        height: ac(370px, 200px);
        filter: grayscale(1);
        z-index: 1;
    }

    @media (min-width: 1024px) {
        .swiper-wrapper {
            width: auto;
            flex-wrap: wrap;
            gap: 40px 20px;
            margin-right: -20px;
        }

        .swiper-slide {
            width: calc(100% / 3 - 20px);
        }
    }

    @mixin tab {
       &:not(.services--about) {
           margin-top: ac(168px, 296px, 375, 1024);
       }

        &__wrapper {
            padding: ac(100px, 286px, 375, 1024) ac(108px, 24px) ac(105px, 190px, 375, 1024) ac(108px, 42px);
            width: calc(100% + 40px);
            margin-left: -20px;
        }

        &__title-box {
            &::before {
                height: calc(100% - 80px);
            }
        }

        &__bottom {
            flex-direction: column;
            align-items: flex-start;
        }

        &__subtitle {
            margin-right: 0;
        }

        &__btn {
            margin-top: 29px;
            margin-bottom: 0;
        }

        &--main {
            .services__title--sec, .services__card-box {
                width: calc(100% + 30px);
                margin-left: -22px;
            }
        }

        &__slider {
            margin-bottom: -21px;
        }
    
        .swiper-grid-column > .swiper-wrapper {
            flex-direction: row;
        }
    
        .swiper-slide {
            height: auto;
            margin-top: 0 !important;
            margin-bottom: 21px !important;
        }
    
        &__card {
            height: 100%;
        }

        &__img {
            bottom: ac(-120px, -70px, 375, 1024);
            right: 0;
        }

        &--about {
            .services__slider-nav {
                margin-top: 40px;
            }

            .services__wrapper {
                padding: * ac(100px, 25px) * ac(216px, 25px);

                &:before {
                    width: ac(80px, 40px);
                    top: -190px;
                }
            }
        }

        &__slider-nav {
            margin-top: ac(40px, 12px);

            .slider-btn {
                border-color: var(--white);

                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='24' fill='none'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='bevel' stroke-width='2' d='M11.25 22.5.75 12l10.5-10.5'/%3E%3C/svg%3E");
                }

            }

            .slider-btn--next {
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='24' fill='none'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='bevel' stroke-width='2' d='M1.75 22.5 12.25 12 1.75 1.5'/%3E%3C/svg%3E");
                }
            }
        }
    }

    @mixin mob-lg {
        &__slider-nav {
            justify-content: center;
            margin-left: -4px;
        }
    }
}