.team {
    position: relative;
    padding: 120px 0 ;
    background-color: var(--cyan);
    z-index: 5;

    &__decor {
        position: absolute;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        transition: 0.3s linear;

        top: 0;
        right: ac(107px, 20px);
        width: ac(176px, 90px);
        height: ac(176px, 90px);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='176' height='176' fill='none'%3E%3Cpath fill='%23C7FD89' d='M176 64.5v47h-64.5V176h-47v-64.5H0v-47h64.5V0h47v64.5H176Z'/%3E%3C/svg%3E");
        transform: translateY(-50%);

        &.active {
            transform: translateY(-50%) rotate(45deg);
        }
    }

    &__wrapper {
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: ac(49px, 40px);

        &::before {
            content: '';
            position: absolute;
            left: -100px;
            top: -120px;
            width: ac(260px, 200px);
            height: ac(839px, 600px);
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='260' height='839' fill='none'%3E%3Cpath stroke='%2334EDFF' d='M61.346 838.5H.5v-75.226h18.015c20.19 0 33.989-4.749 41.325-14.326l.002-.002c3.637-4.802 6.337-11.753 8.131-20.813 1.795-9.065 2.69-20.279 2.69-33.638 0-26.687-.65-52.352-1.95-77.089-1.299-24.67-1.948-51.991-1.948-81.917 0-37.061 5.846-63.75 17.448-80.167 11.608-16.471 29.633-27.618 54.232-33.453l.384-.091v-4.695l-.384-.091c-24.599-5.836-42.624-16.983-54.231-33.453-11.603-16.464-17.45-43.153-17.45-80.168 0-29.879.65-57.2 1.949-81.917 1.3-24.736 1.95-50.402 1.95-77.089 0-13.359-.895-24.572-2.69-33.638-1.794-9.06-4.494-16.01-8.131-20.812-7.291-9.627-21.14-14.328-41.327-14.328H.5V.5h60.846c42.766 0 74.12 9.887 94.127 29.603 19.989 19.699 30.059 55.774 30.059 108.406 0 17.561-.463 33.165-1.437 46.769a1282.883 1282.883 0 0 1-3.432 39.492c-1.3 12.692-2.46 25.386-3.435 38.079-.976 12.704-1.487 26.847-1.487 42.474 0 8.519 1.496 17.034 4.436 25.541 2.948 8.531 7.674 16.214 14.217 23.096 6.556 6.895 15.207 12.463 25.979 16.714 10.675 4.212 23.707 6.675 39.127 7.357v82.938c-15.42.682-28.452 3.145-39.127 7.357-10.772 4.251-19.423 9.819-25.979 16.714-6.544 6.882-11.269 14.612-14.217 23.095v.001c-2.94 8.507-4.436 17.022-4.436 25.541 0 15.627.511 29.77 1.487 42.474.974 12.692 2.088 25.385 3.435 38.079a1282.883 1282.883 0 0 1 3.432 39.492c.974 13.65 1.437 29.255 1.437 46.769 0 52.632-10.07 88.707-30.059 108.406-20.007 19.716-51.315 29.603-94.127 29.603Z'/%3E%3C/svg%3E") center / contain no-repeat;
        }
    }

    &__title {
        color: var(--white);
        padding-left: ac(33px, 16px);

        &::before {
            background: var(--yellow-green);
        }

        span {
            &::before, &::after {
                background-color: var(--yellow-green);
            }
        }
    }

    &__slider-nav {
        .slider-btn {
            border-color: var(--white);
            background: var(--cyan);

            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='24' fill='none'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='bevel' stroke-width='2' d='M11.25 22.5.75 12l10.5-10.5'/%3E%3C/svg%3E");
            }

            &--next {
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='24' fill='none'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='bevel' stroke-width='2' d='M1.75 22.5 12.25 12 1.75 1.5'/%3E%3C/svg%3E");
                }
            }

            &:hover {
                background-color: var(--light-blue);
                border-color: var(--light-blue);
            }
        }
    }

    &__slider {
        overflow: visible !important;
    }

    &__slide {
        color: var(--white);
        cursor: pointer;

        &:nth-child(3n + 1) {
            .team__img {
                &::after {
                    width: 28px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='86' fill='none'%3E%3Cpath fill='%230CCFAA' d='M0 85.986v-7.8h1.994c2.169 0 3.633-.485 4.408-1.448.77-.967 1.154-2.815 1.154-5.55s-.07-5.365-.21-7.9c-.14-2.53-.21-5.33-.21-8.399 0-3.802.63-6.55 1.89-8.247 1.258-1.7 3.212-2.849 5.871-3.449v-.4c-2.659-.6-4.613-1.749-5.872-3.45-1.26-1.7-1.889-4.45-1.889-8.247 0-3.063.07-5.864.21-8.399.14-2.535.21-5.165.21-7.9 0-2.734-.385-4.582-1.154-5.55C5.632 8.28 4.162 7.8 1.994 7.8H0V0h6.606c4.613 0 8.006 1.015 10.175 3.05 2.169 2.033 3.253 5.75 3.253 11.148 0 1.8-.05 3.401-.155 4.797-.105 1.4-.23 2.75-.37 4.05-.14 1.3-.264 2.601-.37 3.902a54.39 54.39 0 0 0-.16 4.35c0 .867.16 1.734.476 2.6a6.38 6.38 0 0 0 1.519 2.35c.7.7 1.624 1.267 2.778 1.7 1.155.434 2.569.686 4.248.753v8.6c-1.68.067-3.093.319-4.248.753-1.154.433-2.079 1-2.778 1.7-.7.7-1.205 1.487-1.52 2.35a7.58 7.58 0 0 0-.474 2.6c0 1.601.055 3.05.16 4.35.105 1.3.225 2.602.37 3.902.14 1.3.264 2.65.37 4.05.104 1.4.154 3.001.154 4.797 0 5.398-1.084 9.114-3.253 11.149C14.612 84.985 11.224 86 6.606 86H0v-.014Z'/%3E%3C/svg%3E");
                }
        
                &::before {
                    width: 44px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' fill='none'%3E%3Cpath fill='%23C7FD89' d='M44 16.125v11.75H27.875V44h-11.75V27.875H0v-11.75h16.125V0h11.75v16.125H44Z'/%3E%3C/svg%3E");
                }
            }
        }

        &:nth-child(3n + 2) {
            .team__img {
                &::after {
                    width: 39px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39' height='44' fill='none'%3E%3Cpath fill='%23C7FD89' d='m39 12.515-23.7 9.447L39 31.405V44L0 27.247V16.724L39 0v12.518-.003Z'/%3E%3C/svg%3E");
                }
        
                &::before {
                    width: 28px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='86' fill='none'%3E%3Cpath fill='%230CCFAA' d='M0 85.986v-7.8h1.994c2.169 0 3.633-.485 4.408-1.448.77-.967 1.154-2.815 1.154-5.55s-.07-5.365-.21-7.9c-.14-2.53-.21-5.33-.21-8.399 0-3.802.63-6.55 1.89-8.247 1.258-1.7 3.212-2.849 5.871-3.449v-.4c-2.659-.6-4.613-1.749-5.872-3.45-1.26-1.7-1.889-4.45-1.889-8.247 0-3.063.07-5.864.21-8.399.14-2.535.21-5.165.21-7.9 0-2.734-.385-4.582-1.154-5.55C5.632 8.28 4.162 7.8 1.994 7.8H0V0h6.606c4.613 0 8.006 1.015 10.175 3.05 2.169 2.033 3.253 5.75 3.253 11.148 0 1.8-.05 3.401-.155 4.797-.105 1.4-.23 2.75-.37 4.05-.14 1.3-.264 2.601-.37 3.902a54.39 54.39 0 0 0-.16 4.35c0 .867.16 1.734.476 2.6a6.38 6.38 0 0 0 1.519 2.35c.7.7 1.624 1.267 2.778 1.7 1.155.434 2.569.686 4.248.753v8.6c-1.68.067-3.093.319-4.248.753-1.154.433-2.079 1-2.778 1.7-.7.7-1.205 1.487-1.52 2.35a7.58 7.58 0 0 0-.474 2.6c0 1.601.055 3.05.16 4.35.105 1.3.225 2.602.37 3.902.14 1.3.264 2.65.37 4.05.104 1.4.154 3.001.154 4.797 0 5.398-1.084 9.114-3.253 11.149C14.612 84.985 11.224 86 6.606 86H0v-.014Z'/%3E%3C/svg%3E");
                }
            }
        }

        &:nth-child(3n + 3) {
            .team__img {
                &::after {
                    width: 27px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='54' fill='none'%3E%3Cpath fill='%230CCFAA' d='M27 53.996H0V30.663C0 22.8 1.593 16.597 4.779 12.048 7.965 7.5 13.429 3.485 21.165 0L27 11.35c-4.758 2.295-8.05 4.578-9.87 6.853-1.821 2.274-2.836 4.96-3.043 8.064h12.909V54l.004-.004Z'/%3E%3C/svg%3E");
                }
        
                &::before {
                    width: 39px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39' height='44' fill='none'%3E%3Cpath fill='%23C7FD89' d='m39 12.515-23.7 9.447L39 31.405V44L0 27.247V16.724L39 0v12.518-.003Z'/%3E%3C/svg%3E");
                }
            }
        }

        &:hover {
            color: var(--light-blue);
        }
    }

    &__img {
        position: relative;
        height: ac(440px, 250px);

        &::after, &::before {
            content: '';
            position: absolute;
            height: ac(86px, 40px);
            background-size: contain;
            background-repeat: no-repeat;
        }

        &::after {
            left: 10px;
            top: -20px;
            background-position: left top;
        }

        &::before {
            right: 10px;
            bottom: -20px;
            background-position: right bottom;
        }
    }

    &__text {
        margin-top: 19px;
        padding-left: 12px;
        border-left: 6px solid var(--yellow-green);
    }

    &__name, &__position {
        transition: .3s ease;
    }

    &__name {
        margin-bottom: 9px;
        font-size: ac(24px, 20px);
        line-height: ac(34px, 30px);
        font-family: var(--font-sec);
        font-weight: 700;
    }
}